
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "bootstrap";
import { datetime } from "@intlify/core-base";
moment.locale("id");
export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [
        {
          createTime: "",
          creatorId: "",
          updateTime: "",
          editorId: "",
          isDelete: false,
          deleteBy: null,
          deleteTime: null,
          purchaseDate: "",
          purchaseDateStart: "",
          purchaseDateUntil: "",
          dateAll: "",
          merchantId: "",
          totalCard: 0,
          totalCardFailed: 0,
          blockedMapping: false,
          _id: "",
          merchantName: "",
          startMapping: null,
          lastMapping: null,
          totalCardMapped: null,
          totalCardSuccess: null,
        },
      ],
      startDate: moment().format("YYYY-MM-DD"),
      listMerchant: [{ label: "", value: "" }],
      merchant: "Pilih Merchant",
      merchantId: {},
      merchantName: "",
      listStatus: [],
      purchaseDate:"",
      purchaseDateStart: "",
      purchaseDateUntil: "",
      dateAll: "",
      isLoading: false,
      fullPage: true,
      filter: false,
      param: false,
      modalEdit: false,
      modalTambah: false,
      placeholder: "Tanggal Pembelian",
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      dateFilter: "",
      dataTemp: {
        createTime: "",
        creatorId: "",
        updateTime: "",
        editorId: "",
        note:"",
        isDelete: false,
        deleteBy: null,
        deleteTime: null,
        purchaseDate: "",
        purchaseDateStart:"",
        purchaseDateUntil:"",
        merchantId: "",
        totalCard: 0,
        totalCardFailed: 0,
        blockedMapping: false,
        _id: "",
        merchantName: "",
        startMapping: null,
        lastMapping: null,
        totalCardMapped: null,
        totalCardSuccess: null,
        totalCardActive: null,
      },
      changeDate:false,
      dateOld:"",
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      errorDate: false,
      errorMerchant: false,
      errorCard: false,
      errorNote: false,

      addPurchasing: {
        purchaseDate: "",
        purchaseDateStart:"",
        purchaseDateUntil:"",
        merchantId: "",
        totalCard: 0,
        note: "",
      },
      
      name: "",
      categoryId: "",
      role: "",
      disabledMerchant : false,
    };
  },

  beforeMount() {
    const route = useRoute();
    // store.dispatch(Actions.VERIFY_AUTH);
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    var parameter = "";
    this.merchantId = route.params.id == "all" ? "" : "" + route.params.id;
    this.addPurchasing.merchantId =
      route.params.id == "all" ? "" : "" + route.params.id;
    this.merchant = this.addPurchasing.merchantId;
    this.param = route.params.id == "all" ? true : false;
    this.getData(this.paging.size, this.paging.page);
    this.getListKategori();
    setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      this.isLoading = true;
      console.log(this.purchaseDateStart,this.purchaseDateUntil)

      let search = "";
      let valcategoryId = "";
      if (this.categoryId) {
        valcategoryId = this.categoryId;
      }
      if (this.name) {
        search = this.name;
      }
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      let search1 = "";
      if (this.purchaseDateStart) {
        search1 = "purchaseDateStart=" + this.purchaseDateStart + "&";
      } else {
        search1;
      }

      let search2 = "";
      if (this.purchaseDateUntil) {
        search2 = "purchaseDateUntil=" + this.purchaseDateUntil + "&";
      } else {
        search2;
      }
      let search3 = "";
      if (this.merchantId) {
        search2 = "merchantId=" + this.merchantId + "&";
      } else {
        search2;
      }
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_purchasing/" +
          office +
          "all?" +
          'merchantId='+ this.merchantId +
          '&purchaseDateStart='+this.purchaseDateStart+
          '&purchaseDateUntil='+this.purchaseDateUntil+
          "&page=" +
          page +
          "&size=" +
          size +
          "&sort=name&dir=1"
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    filterClick() {
      this.filter = !this.filter;
    },
    pilihTanggalAll(event) {
      console.log(event,'dan',this.dateAll)
      this.purchaseDateStart = this.dateAll[0];
      this.purchaseDateUntil = this.dateAll[1];

      localStorage.setItem("dateAll", JSON.stringify(this.dateAll));
      localStorage.setItem(
        "tanggalAwal",
        JSON.stringify(this.purchaseDateStart)
      );
      localStorage.setItem(
        "tanggalAkhir",
        JSON.stringify(this.purchaseDateUntil)
      );
      this.getData(this.paging.size, this.paging.page);
    },
    pilihMerchant(e) {
      console.log(e);

      this.merchantId = e == null ? "" : e;
      
      this.getData(this.paging.size, this.paging.page);

      this.listMerchant[0].label = "Pilih Merchant";
      this.listMerchant[0].value = "";
    },
    pilihTanggal(e) {
      this.dateFilter = e.target.value;
      this.getData(this.paging.size, this.paging.page);
    },
    cek(e) {
      this.addPurchasing.purchaseDate = e;
      this.addPurchasing.purchaseDateStart = e;
      this.addPurchasing.purchaseDateUntil = e;

    },
    clickReset() {
      this.param = true;
      this.dateAll="";
      this.merchantId = "";
      this.merchant = "";
      this.addPurchasing.merchantId = "";
      this.getData(this.paging.size, this.paging.page);
      this.$router.push('/superadmin/data-pembelian/all');
      window.location.reload();
      // var cekstatus = event == "" || event == '' ? "" : ""
      // this.schoolId = cekstatus

      // console.log("rerst", event.target.value);
      // localStorage.setItem("sekolahId", JSON.stringify(""));
      // this.dateAll = "";
      // this.merchantId = "";
      // // this.setItem("sekolahId")
      // //  event.preventDefault();
      // // remove existing saved config
      // // localStorage.removeItem("schoolId");
      // this.getData(this.paging.size, this.paging.page);
      // // window.location.reload();
      // event.preventDefault();
      // // remove existing saved config
      // localStorage.removeItem("");
      // window.location.reload();
    },
    getDataById(event) {
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_purchasing/" + office + event._id
      )
        .then((resp) => {
          console.log(resp.data.purchaseDate);
          this.dataTemp = resp.data;
          this.dateOld = resp.data.purchaseDate
          this.dataTemp.purchaseDate = moment(
            resp.data.purchaseDate
          ).format("DD-MMMM-YYYY");
          
          // alert(resp.data.purchaseDate)
          // this.dataTemp.purchaseDateStart = moment(
          //   this.dataTemp.purchaseDateStart
          // ).format("DD-MMMM-YYYY");
          // this.dataTemp.purchaseDateUntil = moment(
          //   this.dataTemp.purchaseDateUntil
          // ).format("DD-MMMM-YYYY");
          this.modalEdit = true;
        })
        .catch((e) => {
          console.log(e);
        });
      // console.log(event);
    },
    getListKategori() {
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "all";
      } else {
        office = "office/combo_all";
      }
      ApiService.getWithoutSlug("crmv2/main_card/card_merchant/" + office).then(
        ({ data }) => {
          var as = [
            {
              nama: "",
              _id: "",
            },
          ];
          if (this.role == "ADMIN_SCHOOL") {
            as = data.content;
            var valueA = <any>[];
            as.forEach((element) => {
              valueA.push({
                label: element.nama,
                value: element._id,
              });
            });
            this.addPurchasing.merchantId = as[0]._id;
            this.listMerchant = valueA;
          } else {
            as = data;
            var tes = [
              {
                label: "Pilih Merchant",
                value: "",
              },
            ];
            as.forEach((element) => {
              tes.push({
                label: element.nama,
                value: element._id,
              });
            });

            this.listMerchant = tes;
          }

          // localStorage.setItem("listMerchant", JSON.stringify(data));
          // location.reload();
        }
      );
    },

    add() {
      const toast = useToast();
      const route = useRoute();
      const router = useRouter();

      // console.log(this.addPurchasing);
      if (
        this.addPurchasing.purchaseDate == "" &&
        this.addPurchasing.merchantId == "" &&
        this.addPurchasing.totalCard == 0 &&
        this.addPurchasing.note == ""
      ) {
        return (
          (this.errorCard = true),
          (this.errorDate = true),
          (this.errorMerchant = true)
          // (this.errorNote = true)
        );
      }
      if (
        this.addPurchasing.purchaseDate == "" ||
        this.addPurchasing.purchaseDate == null
      ) {
        return (
          toast.error("Tanggal Tidak Boleh Kosong"), (this.errorDate = true)
        );
      }
      if (this.addPurchasing.merchantId == "") {
        return (
          toast.error("Merchant Tidak Boleh Kosong"),
          (this.errorMerchant = true)
        );
      }
      if (this.addPurchasing.totalCard == 0) {
        return toast.error("Masukkan Total Kartu"), (this.errorCard = true);
      }
      // if (this.addPurchasing.note == "") {
      //   return toast.error("Catatan Tidak Boleh"), (this.errorNote = true);
      // }
      this.isLoading = true;
      var timeNow = "";
      timeNow = moment().format("h:mm");
      var tanggalbeli = moment(this.addPurchasing.purchaseDate).format(
        "YYYY-MM-DD"
      );
      var bdy = <any>{};
      bdy = this.addPurchasing;
      bdy.purchaseDate = tanggalbeli + "T" + timeNow;
      console.log(bdy.purchaseDate, " <==> ", tanggalbeli, "bodey");
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }

      ApiService.postWithData("crmv2/main_card/card_purchasing/" + office, bdy)
        .then((resp) => {
          toast.success("Berhasil Menambahkan Data");
          this.getData(this.paging.size, this.paging.page);
          this.modalTambah = false;
          setTimeout(() => {
            window.location.reload();
          }, 200);
        })
        .catch((e) => {
          this.addPurchasing.purchaseDate = tanggalbeli + "";
          toast.error(e.response.data.detail);
          this.isLoading = false;
        });
    },

    pilihKategori(event) {
      // this.schoolId = event;
      if (event) {
        this.categoryId = event;
      } else {
        this.categoryId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },

    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    updatePurchasing() {
      const toast = useToast();
      var timeNow = moment().format("h:mm");
      var fulldate = this.dataTemp.purchaseDate + " " + timeNow;
      console.log(this.dataTemp.purchaseDate,this.dateOld, "cde");
      // alert(fulldate)
      var purcasingDate = this.changeDate == true? moment(fulldate).format("YYYY-MM-DDTHH:mm:ssZ"):this.dateOld
      var body = {
        purchaseDate: purcasingDate,
        merchantId: this.dataTemp.merchantId,
        totalCard: this.dataTemp.totalCard,
        blockedMapping: this.dataTemp.blockedMapping,
        totalCardFailed: this.dataTemp.totalCardFailed,
        note: this.dataTemp.note,
      };
      console.log(body)
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }

      ApiService.putWithData(
        "crmv2/main_card/card_purchasing/" + office + this.dataTemp._id,
        body
      )
        .then((resp) => {
          toast.success("Berhasil Update");
          console.log(resp);
          this.getData(this.paging.size, this.paging.page);
          setTimeout(() => {
            // window.location.reload();
            this.modalEdit = false;
          }, 2000);
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
        });
    },
    formatNumber(e) {
      var a = e;
      if (e == null) {
        a = 0;
      }
      return a + "";
    },
    deleteRow(item) {
      const toast = useToast();
      this.isLoading = true;

      // ApiService.delete(
      //   "crmv2/main_order/product/" + item._id
      // )
      //   .then((res) => {
      //     toast.success("Hapus Berhasil");

      //     this.getData(this.paging.size, this.paging.page);
      //     this.isLoading = false;
      //   })

      //   .catch((error) => {
      //     toast.error("Gagal Hapus");
      //     this.isLoading = false;
      //   });
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    clickBatal()
     {
      if(this.role != 'ADMIN_SCHOOL'){
        this.addPurchasing = {
        purchaseDate: "",
        purchaseDateStart:"",
        purchaseDateUntil:"",
        merchantId: "",
        totalCard: 0,
        note: "",
      }}else{
        this.addPurchasing = {
        purchaseDate: "",
        purchaseDateStart:"",
        purchaseDateUntil:"",
        merchantId: this.addPurchasing.merchantId,
        totalCard: 0,
        note: "",
      }
      };
      this.errorDate = false;
      this.errorMerchant = false;
      this.errorCard = false;
      // this.errorNote = false;
      this.modalEdit = false;
      this.modalTambah = false;
    },
    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize() {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    changeModal() {},

    dateTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
});
